import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
//bootstrap icones
import { ToggleOff, ToggleOn } from 'react-bootstrap-icons';

import { 
    Link
  } from "react-router-dom"; 



export default function Checkbox(props){

    return (
        <>
{props.isActive?(
    <Link onClick={()=>props.onClickFunction()}>
<ToggleOn size={props.size} color={props.colorActive} />
</Link>
):(
    <Link onClick={()=>props.onClickFunction()}>
<ToggleOff size={props.size} color={props.color} />
</Link>
)}
        </>
    );
}