// export const GLOBAL_apiPath = "http://localhost:9898/playstrict_backend/public/index.php/";
// export const serwer_publicPath = "http://localhost:9898/playstrict_backend/public/";

// export const GLOBAL_apiPath = "https://codergasm-live.com/codergasm/klienci/2020/playstrict/backend/public/index.php/";
// export const serwer_publicPath = "https://codergasm-live.com/codergasm/klienci/2020/playstrict/backend/public/";

export const GLOBAL_apiPath = "/playstrict_backend/public/index.php/";
export const serwer_publicPath = "/playstrict_backend/public/";


export const playstrict_platformPath = "https://mvp.playstrict.com/";