import React, { useState, useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom"; 
  import AppContext from '../../context.js';



  //comp
  import Footer from '../../components/Footer.js';


  //pages
  import GamesCategories from './GamesCategories.js';
  import GamesTags from './GamesTags.js';
  import Games from './Games.js';
  import GamesEditContent from './GamesEditContent.js';
  import SlidersList from './SlidersList.js';
  import SlidersEditContent from './SlidersEditContent.js';


  //img
import img_devLogo from '../../assets/img/dev_logo.png'; 

//bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

//bootstrap icones
import { DoorClosedFill, FilterRight, BookmarkPlus, Collection, Controller } from 'react-bootstrap-icons';


export default function PanelStack(props){
    const contextObject = useContext(AppContext);


    return (
<>
<Container>
<div className="panel_head">

<Link to="/"><img src={img_devLogo} /></Link>

<Link>
<Button className="main_btn" onClick={()=>contextObject._logout()}>
<DoorClosedFill size={20} color="#fff" /> 
</Button>
</Link>


</div>


<Row className="content_row">
<Col xl={3} lg={3} md={4} sm={12} xs={12}>
 

<div className="nav_column">


{contextObject.AUTH_userRange === "global_admin" ?(
  <>
  <Link to="/panel/games-categories"><FilterRight size={20} /><p>Kategorie gier</p></Link>
  <Link to="/panel/games-tags"><BookmarkPlus size={20} /><p>Tagi gier</p></Link>
  <Link to="/panel/sliders-list"><Collection size={20} /><p>Slidery</p></Link>
  </>
):null}




<Link to="/panel/games"><Controller size={20} /><p>Lista gier</p></Link>

{/* 

<Link to="/panel/transactions"><Basket3Fill size={20} /><p>Transakcje</p></Link>
<Link to="/panel/games-tags"><FunnelFill size={20} /><p>Tagi gier</p></Link>
<Link to="/panel/transactions"><Grid3x3GapFill size={20} /><p>Slidery</p></Link>
*/}

</div>
</Col>

<Col xl={9} lg={9} md={8} sm={12} xs={12}> 
<Switch>
 
  <Route path="/panel/games-categories">
  <GamesCategories />
  </Route> 


  <Route exact path="/panel/games-tags">
  <GamesTags />
  </Route> 

  <Route exact path="/panel/games">
  <Games />
  </Route> 


  <Route exact path="/panel/sliders-list">
<SlidersList />
</Route>

<Route exact path="/panel/sliders/edit-content/:sliderId">
<SlidersEditContent />
</Route>


  <Route path="/panel/games/edit-content/:gameId">
  <GamesEditContent />
  </Route> 



</Switch> 
</Col>


</Row>





</Container>






<Footer />

</>
    );
}