import React, { useState } from 'react';
import Footer from '../components/Footer.js';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom"; 

//img
import img_devLogo from '../assets/img/dev_logo.png';
import img_dev1 from '../assets/img/img_dev1.png';
import img_dev2 from '../assets/img/img_dev2.png';

//bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';



export default function HomePage(props){
    return (
        <>
 

        <Container>
<Row>
<Col xl={8} lg={8} md={6} sm={12} xs={12} className="home_top_column_left">


<div className="home_top_nav">
 <Row style={{width: '100%'}}>
<Col xl={6} lg={6} md={12} sm={12} xs={12}>
<Link to="/">
<img src={img_devLogo} />
</Link>
</Col>

<Col xl={6} lg={6} md={12} sm={12} xs={12}>
<div className="btns_group">
<Button className="main_btn" onClick={()=>props.showLoginModalFunction()}>
Zaloguj się
</Button>

<Button className="main_btn" onClick={()=>props.showRegisterModalFunction()}>
Rejestracja
</Button>
</div>
</Col>
 


</Row>
</div>

<div className="home_top_left_content">

<h1>
Jesteś twórcą gier?<br/>
Opublikuj je na Playstrict<span style={{color: '#2ae3e6'}}>.</span>
</h1>

<p>Wejdź do świata gier dla Developerów, rozwijaj i dodawaj swoje produkty. <br />
Dostęp do wszystkich statystyk Twoich gier w zasięgu ręki.</p>

<div style={{
    display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
    }}>
    <Button className="main_btn" onClick={()=>props.showLoginModalFunction()}>Dodaj swoją grę</Button>
    
    <div style={{
    marginLeft: 20, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
    }}>
    <p>Codzienna aktualizacja</p>
    <p style={{fontWeight: 'bold', color: '#32ddef', marginTop: -15}}>Ponad 10 000 zadowolonych graczy</p>
    </div>
    </div>


    </div>
</Col>

<Col xl={4} lg={4} md={6} sm={12} xs={12} className="home_top_slider d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">

<div className="slide_item">
<img src={img_dev1} />

<p>
<span style={{fontWeight: 'bold'}}>Gra: The Landscape Adventure</span><br />
Liczba pobrań 10 203
</p>
</div>

</Col>


</Row>
</Container>


<Row style={{width: '100%', background: '#e8eff5'}}>
<Container>
<Row>
<div className="horizontal_slider_item">
<Col xl={6} lg={4} md={6} sm={2} xs={2}>
<img src={img_dev2} />
</Col>
<Col xl={6} lg={7} md={6} sm={10} xs={10} className="right_column">
<h2>
Playstrict.dev nowe możliwości dla Twoich produkcji
</h2>

<p className="p_main">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam nisi augue. Nunc ultricies justo quis euismod egestas. Duis eu turpis sit amet ipsum hendrerit dapibus vitae eu diam.

</p>

<p className="p_second">
Marcin Nowak, Specjalista ds. marketingu w Playstrict
</p>

<Button className="main_btn">
Czytaj więcej
</Button>

</Col>

</div>

</Row>
</Container>
</Row>






<Footer />

</>
    );
}