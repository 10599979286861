import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom"; 
  import axios from "axios";
import {GLOBAL_apiPath, serwer_publicPath} from '../../config/GlobalSettings.js';
  import AppContext from '../../context.js';



  //bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

//bootstrap icones
import { XCircleFill, CloudUpload, Upload, XSquare, Folder} from 'react-bootstrap-icons';

  


export default function GamesCategories(props){

    const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);
const [returnedArray, setReturnedArray] = useState([]);
const [uploadBlobFile, setUploadBlobFile] = useState("");
const [newItemName, setNewItemName] = useState("");


const [loadingFilesList, setLoadingFilesList] = useState(false);
const [loadingUploadingFile, setLoadingUploadingFile] = useState(false);
const [showFilemanagerModal, setShowFilemanagerModal] = useState(false);
const [userFilesArray, setUserFilesArray] = useState([]); 

const [targetFilePathPasteObject, setTargetFilePathPasteObject] = useState("");
const [targetFilePathPasteObjectIndex, setTargetFilePathPasteObjectIndex] = useState("");

const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);



const onDrop = useCallback((acceptedFiles) => {
  acceptedFiles.forEach((file) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
    // Do whatever you want with the file contents
      const binaryStr = reader.result;
      console.log(binaryStr);
      setUploadBlobFile(binaryStr);
    }
    reader.readAsDataURL(file);
  })
  
}, [])


const pasteImagePath = (pathString) =>{
  setShowFilemanagerModal(false); 

 
  setReturnedArray((prevState)=>{ 
  prevState[targetFilePathPasteObjectIndex][targetFilePathPasteObject] = pathString; 
  return(prevState); 
})
 

forceUpdate(); 

}




const {acceptedFiles, getRootProps, getInputProps, isDragActive,  isDragAccept, isDragReject} = useDropzone({onDrop, accept: 'image/*', maxSize: 15000000});
  
 

const baseStyle = {  
  };



  const activeStyle = {
    background: '#10d123', borderColor:'#fff'
  };
   

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {})
  }), [
    isDragActive
  ]);




useEffect(() => { 
    getArrayAjax();
  }, []);




  const getFileListAjax = () =>{ 
    setLoadingFilesList(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash
    };
    axios.post(GLOBAL_apiPath+`user-files/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoadingFilesList(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setUserFilesArray(res.data.returnArray);
       }
    }).catch(err => {
      setLoadingFilesList(false); 
    })
  }


  const addNewFile = () =>{

    if(acceptedFiles.length>0){ 
      setLoadingUploadingFile(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        file: uploadBlobFile,
        fileName: acceptedFiles[0].path
    };
    axios.post(GLOBAL_apiPath+`user-files/create`, { obj })
    .then(res => {   
      setLoadingUploadingFile(false); 
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getFileListAjax();
       }
    }).catch(err => {
      setLoadingUploadingFile(false); 
    })
 
    }else{
        contextObject._runAlert("Wymagane dodanie pliku");
    }

  }
















  const getArrayAjax = () =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
    };
    axios.post(GLOBAL_apiPath+`games-categories/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoading(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setReturnedArray(res.data.returnArray);
       }
    }).catch(err => {
      setLoading(false); 
    })
  }




  const deleteItem = (id) =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        rowId: id
    };
    axios.post(GLOBAL_apiPath+`games-categories/delete`, { obj })
    .then(res => {  
    
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })
  }











  const handleItemEdit = (index) =>{ 

setLoading(true);  
var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    name: returnedArray[index].name,
    iconPath: returnedArray[index].iconPath,
    bgImagePath: returnedArray[index].bgImagePath,
    rowId: returnedArray[index].id
};
axios.post(GLOBAL_apiPath+`games-categories/edit`, { obj })
.then(res => {  

  if(res.data.authError === true){ 
    contextObject._logout();
   }else{
     //sukces! - pobieramy więc aktualną listę
     getArrayAjax();
   }
}).catch(err => {
  setLoading(false); 
})
  }









  const addNewItem = () =>{
  
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
        name: newItemName
    };
    axios.post(GLOBAL_apiPath+`games-categories/create`, { obj })
    .then(res => {  
    setNewItemName("");
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })
 
   

  }


    return(
<>




{showFilemanagerModal?(
  <>
  
  <div className="fixed_modal_bg">
  </div>
  
  
  <div className="main_modal_overall">
  <div className="main_modal">
  <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
  <p className="modal_title">Pliki</p>
  
  <Link onClick={()=>{
    setShowFilemanagerModal(false);
  }}>
  <XSquare size={20} color="#5528e5" />
  </Link>
  </div>
  
  
  <p style={{
    color: "#1f0573",
    fontSize: 15
  }}>Twoje pliki:
  
  
  {loadingFilesList? <Spinner animation="border" size="sm" variant="dark" className="main_spinner" /> : null} 
  
  
  </p>
  
  <div style={{
    overflow: 'auto', maxHeight: 400
  }}>
  
  
  {userFilesArray.map((fileItem,fileIndex) => (


  <Button className="main_input flex-center-center"
  onClick={()=>{
    pasteImagePath(fileItem.filePath);
  }}
  >

  {fileItem.isImage ?(
<img src={serwer_publicPath+fileItem.filePath} style={{height: 50, marginRight: 50}} />
  ):null}

  <p>{fileItem.name}</p>
  </Button> 


  ))}
  
  
  </div>
  
  
  
  <p style={{
    color: "#1f0573",
    fontSize: 15
  }}>Dodaj nowy:</p>
  
  
  <div style={{
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  }}>
  
  <div {...getRootProps({className: 'dropzone dropzone-in-modal', style: style})}>
          <input {...getInputProps()} />
          <Upload />
        </div>
  
  
  <Button className="main_btn" style={{paddingBottom: 20, paddingTop: 20, marginLeft: 5, width: '25%'}}
  onClick={()=>addNewFile()}
  >
  {loadingUploadingFile? <Spinner animation="border" size="sm" variant="light" className="main_spinner" /> : "wgraj"} 
  </Button>
  
  
        </div>
      
  
        {acceptedFiles.length>0? acceptedFiles[0].path:null}
  
        <br /><br /> 
  
  
  </div>
  </div>
  
  
  </>
  ):null}



















<h1 className="panel_page_title">
Kategorie gier
{loading? <Spinner animation="border" size="md" variant="dark" className="main_spinner" /> : null} 
</h1>

<div className="table_overall">
  
    <table>
    <tr>
    <td>id</td>
    <td>nazwa</td>
    <td>ikona</td>
    <td>grafika<br />w tle</td>
    <td>zapis</td>
    <td>&nbsp;</td>
    </tr>
    {returnedArray.map((item,index) => (
        <tr>  
        
        <td>
        {item.id}
        </td>
        <td>
        <input type="text" defaultValue={item.name} name="name" onChange={e=>{
            const val = e.target.value;  
            setReturnedArray((prevState)=>{
                prevState[index].name = val;
                console.log('prev stejt: ');
                console.log(prevState);
                return(prevState);
            })

            console.log(returnedArray);
        }} />
        </td>
        <td>


        <Link
        onClick={()=>{
          setTargetFilePathPasteObject("iconPath");
          setTargetFilePathPasteObjectIndex(index);
          getFileListAjax();
          setShowFilemanagerModal(true);
        }}
        >

        {item.iconPath!= null ?(
          <img src={serwer_publicPath+item.iconPath} className="game_category_ico" />
        ):(
        <Folder size={40} color="#000" />
        )}
        


        </Link>
        
        </td>


        <td>
        <Link
        onClick={()=>{
          setTargetFilePathPasteObject("bgImagePath");
          setTargetFilePathPasteObjectIndex(index);
          getFileListAjax();
          setShowFilemanagerModal(true);
        }}
        >
{item.bgImagePath!= null ?(
  <img src={serwer_publicPath+item.bgImagePath} className="game_category_ico" />
):(
<Folder size={40} color="#000" />
)}

        


        </Link>

        
        </td>



        <td><Button onClick={()=>handleItemEdit(index)}><CloudUpload size={30}  /></Button></td>
        <td><Link onClick={()=>deleteItem(item.id)}><XCircleFill size={30} /></Link></td>
         
        </tr>
    ))}

<tr className="tr_add_new">
<td colspan="5">
<p>Dodaj nową</p>
</td>
</tr>

    <tr>  
        
    <td>
    &nbsp;
    </td>
    <td colspan="3">
    <input type="text" placeholder="Wpisz nazwę..." value={newItemName} onChange={e=>setNewItemName(e.target.value)} />
    </td>
  
    <td><Button onClick={()=>addNewItem()}><CloudUpload size={30}  /></Button></td>
    <td>&nbsp;</td>
     
    </tr>

    </table> 
 
</div>
 

<div className="hr_separator"></div>

 

</>
    );
}