import React, { useContext, useState } from 'react';  
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import axios from "axios";
import {GLOBAL_apiPath} from '../config/GlobalSettings.js';
import AppContext from '../context.js';

//bootstrap
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

//bootstrap icones
import { XSquare } from 'react-bootstrap-icons';
 

//imgs
import img_playstrictMainLogo from '../assets/img/logo_color_main.png';
import img_mainSliderSample from '../assets/img/main_slider_sample.png';
import img_arrowLeftWhite from '../assets/img/arrow_left_white.png';
import img_arrowRightWhite from '../assets/img/arrow_right_white.png';
import img_blogSampleImg from '../assets/img/blog_sample_img.png';
import img_footerImg from '../assets/img/footer_img.png';
import img_gameCoverSample from '../assets/img/game_cover_sample.png';
import img_gradientBg from '../assets/img/gradient_bg.png';
import img_icoAppStore from '../assets/img/ico_app_store.png';
import img_icoGooglePlay from '../assets/img/ico_google_play.png';
import img_leftArrowCircle from '../assets/img/ico_left_arrow_circle.png';
import img_rightArrowCircle from '../assets/img/ico_right_arrow_circle.png';
import img_logoColorMini from '../assets/img/logo_color_mini.png';
import img_logoWhiteMini from '../assets/img/logo_white_mini.png';
import img_icoPlay from '../assets/img/play_ico.png';
import img_icoSearch from '../assets/img/ico_search.png';
import img_icoPlusCircle from '../assets/img/plus_circle_ico.png';
import img_sliderSampleImg from '../assets/img/slider_sample_img.png';
import img_socialIcons from '../assets/img/social_icons.png';

export default function LoginModal(props) {

const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);


const handleLogin = (e) =>{
  e.preventDefault();

  setLoading(true); 

  var obj = {
    email: e.target['email'].value,
    password: e.target['password'].value
  };
  axios.post(GLOBAL_apiPath+`auth/get-hash`, { obj })
  .then(res => { 
     console.log(res.data);
     setLoading(false); 

    if(res.data.authError === true || res.data.userRange == "player"){ 
      contextObject._runAlert("Nieprawidłowe dane logowania, lub konto niezweryfikowane.");
     }else{
       //sukces! logujemy
      contextObject._set_AUTH_userEmail(res.data.userEmail);
      contextObject._set_AUTH_userHash(res.data.hash);
      contextObject._set_AUTH_userNick(res.data.userNick);
      contextObject._set_AUTH_userRange(res.data.userRange);

      localStorage.setItem('AUTH_userEmail', res.data.userEmail);
      localStorage.setItem('AUTH_userHash', res.data.hash);
      localStorage.setItem('AUTH_userNick', res.data.userNick);
      localStorage.setItem('AUTH_userRange', res.data.userRange);

      props.closeFunction();
     }
  }).catch(err => {
    setLoading(false); 
  })





};

  return (
<>

 
<div className="fixed_modal_bg" ></div>
 
<div className="main_modal_overall">
<div className="main_modal">
<div className="main_modal_overflow">
<div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
<p className="modal_title">Witamy w playstict!</p>

<Link onClick={()=>{
  props.closeFunction();
}}>
<XSquare size={20} color="#5528e5" />
</Link>
</div>

 <form onSubmit={handleLogin}>

<p className="p_over_input">Adres e-mail</p>
<input type="text" placeholder="" className="main_input" name="email" />

<p className="p_over_input">Hasło</p>
<input type="password" placeholder="" className="main_input" name="password" />
 


<Button 
type="submit" 
className="main_btn" style={{width: '90%', marginLeft: '5%', marginTop: 20, marginBottom: 20, borderRadius: 10, paddingTop: 11, paddingBottom: 11}}>
{loading?(
  <Spinner animation="border" size="sm" variant="light" />
):"Zaloguj"} 
</Button>
</form>

<div className="hr_separator_modal"></div>

<p className="modal_footer_p">
<Link onClick={()=>props.showRegisterModalFunction()}>Zarejestruj się </Link>
jeśli nie posiadasz konta Playstrict
</p>

</div>
</div>
</div>



</> 
  );
} 


 