import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom"; 
  import axios from "axios";
import {GLOBAL_apiPath, serwer_publicPath} from '../../config/GlobalSettings.js';
  import AppContext from '../../context.js';


  //comp
import Checkbox from '../../components/Checkbox.js';

  //bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

//bootstrap icones
import { XCircleFill, CloudUpload, Upload, XSquare, Folder} from 'react-bootstrap-icons';

  


export default function GamesTags(props){

    const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);
const [returnedArray, setReturnedArray] = useState([]); 
const [newItemName, setNewItemName] = useState("");
 

const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);

const [getAssociatedTagsFlag, setGetAssociatedTagsFlag] = useState(false);
 
 

 
 


useEffect(() => { 
    getArrayAjax();
  }, []);







 const getTagsByGroupId_ajax = (groupIndex) =>{


  setReturnedArray((prevState)=>{
    prevState[groupIndex].loadingAssociatedTags = true;  
    return(prevState);
});
forceUpdate();

  var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    groupId: returnedArray[groupIndex].id
};
  axios.post(GLOBAL_apiPath+`games-tags/list`, { obj })
  .then(res => { 

    console.log('tagi podrzedne: dla id grupy: '+returnedArray[groupIndex].id);
     console.log(res.data.returnArray); 

    if(res.data.authError === true){ 
      contextObject._logout();
     }else{
       //sukces!   
       setReturnedArray((prevState)=>{
        prevState[groupIndex].loadingAssociatedTags = false; 
        prevState[groupIndex].associatedTagsArray = res.data.returnArray;
        return(prevState);
    });
forceUpdate();
     }
  }).catch(err => {
    setReturnedArray((prevState)=>{
      prevState[groupIndex].loadingAssociatedTags = false;  
      return(prevState);
  });
  forceUpdate();
  console.log("error while loading tags of group id: "+returnedArray[groupIndex].id);


  }) 
 }




 const deleteTag = (tagIndex, groupIndex) =>{
  setReturnedArray((prevState)=>{
    prevState[groupIndex].loadingAssociatedTags = true;  
    return(prevState);
});
forceUpdate();
  var obj = {
      email: contextObject.AUTH_userEmail,
      hash: contextObject.AUTH_userHash, 
      rowId: returnedArray[groupIndex].associatedTagsArray[tagIndex].id
  };
  axios.post(GLOBAL_apiPath+`games-tags/delete`, { obj })
  .then(res => {  
  
    if(res.data.authError === true){ 
      contextObject._logout();
     }else{
       //sukces! - pobieramy więc aktualną listę
       getTagsByGroupId_ajax(groupIndex);
     }
  }).catch(err => {
    setReturnedArray((prevState)=>{
      prevState[groupIndex].loadingAssociatedTags = false;  
      return(prevState);
  });
  forceUpdate();
  })
}











const editTag = (tagIndex, groupIndex) =>{ 

  setReturnedArray((prevState)=>{
    prevState[groupIndex].loadingAssociatedTags = true;  
    return(prevState);
});
forceUpdate();

var obj = {
  email: contextObject.AUTH_userEmail,
  hash: contextObject.AUTH_userHash,
  name: returnedArray[groupIndex].associatedTagsArray[tagIndex].name,
  rowId: returnedArray[groupIndex].associatedTagsArray[tagIndex].id
};
axios.post(GLOBAL_apiPath+`games-tags/edit`, { obj })
.then(res => {  

if(res.data.authError === true){ 
  contextObject._logout();
 }else{
   //sukces! - pobieramy więc aktualną listę
   getTagsByGroupId_ajax(groupIndex);
 }
}).catch(err => {
  setReturnedArray((prevState)=>{
    prevState[groupIndex].loadingAssociatedTags = false;  
    return(prevState);
});
forceUpdate();
})
}









const newTag = (groupIndex) =>{

  if(returnedArray[groupIndex].newTagName.length === 0){
alert("Wypełnij pole tekstowe");
  }else{
    setReturnedArray((prevState)=>{
      prevState[groupIndex].loadingAssociatedTags = true;  
      prevState[groupIndex].newTagName = "";  
      return(prevState);
  });
  forceUpdate();
  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
        name: returnedArray[groupIndex].newTagName,
        groupId: returnedArray[groupIndex].id
    };
    axios.post(GLOBAL_apiPath+`games-tags/create`, { obj })
    .then(res => {  
    setNewItemName("");
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getTagsByGroupId_ajax(groupIndex);
       }
    }).catch(err => {
      setReturnedArray((prevState)=>{
        prevState[groupIndex].loadingAssociatedTags = false;  
        return(prevState);
    });
    forceUpdate();
    })
  
  }


 

}























const getAllAssociatedTags_forEachLoop = ()=>{
  console.log('szwa');
  console.log(returnedArray);
returnedArray.forEach((element, elementIndex) =>{ 
  console.log('hrabi');
  getTagsByGroupId_ajax(elementIndex);
});

setGetAssociatedTagsFlag(false);

return null;

}




  const getArrayAjax = () =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
    };
    axios.post(GLOBAL_apiPath+`games-tags-groups/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoading(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setReturnedArray(res.data.returnArray);   
 setGetAssociatedTagsFlag(true);



       }
    }).catch(err => {
      setLoading(false); 
    })
  }




  const deleteItem = (id) =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        rowId: id
    };
    axios.post(GLOBAL_apiPath+`games-tags-groups/delete`, { obj })
    .then(res => {  
    
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })
  }











  const handleItemEdit = (index) =>{ 

setLoading(true);  
var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    name: returnedArray[index].name,
    canSelectOnlyOneFromThisGroup: returnedArray[index].canSelectOnlyOneFromThisGroup,
    rowId: returnedArray[index].id
};
axios.post(GLOBAL_apiPath+`games-tags-groups/edit`, { obj })
.then(res => {  

  if(res.data.authError === true){ 
    contextObject._logout();
   }else{
     //sukces! - pobieramy więc aktualną listę
     getArrayAjax();
   }
}).catch(err => {
  setLoading(false); 
})
  }









  const addNewItem = () =>{

    if(newItemName.length ===0){
      alert("Wypełnij pole tekstowe !");
    }else{
      setLoading(true);  
      var obj = {
          email: contextObject.AUTH_userEmail,
          hash: contextObject.AUTH_userHash,
          name: newItemName
      };
      axios.post(GLOBAL_apiPath+`games-tags-groups/create`, { obj })
      .then(res => {  
      setNewItemName("");
        if(res.data.authError === true){ 
          contextObject._logout();
         }else{
           //sukces! - pobieramy więc aktualną listę
           getArrayAjax();
         }
      }).catch(err => {
        setLoading(false); 
      })
    }
  

 
   

  }


    return(
<>



 
 





<h1 className="panel_page_title">
Tagi gier
{loading? <Spinner animation="border" size="md" variant="dark" className="main_spinner" /> : null} 
</h1>

<p style={{color: 'grey', marginTop: 10}}>
Zależnie od zaznaczenia opcji "monopolizuj", użytkownik (zarówno użytkownik portalu, jak i deweloper) będzie
mógł zaznaczyć jeden tag lub wiele tagów w obrębie grupy
</p>

<div className="table_overall">
  
    <table>
    <tr>
    <td>id</td>
    <td>nazwa grupy</td>  
    <td>monopolizuj?</td>
    <td>zapis</td> 
    <td>&nbsp;</td>
    </tr>


    {returnedArray.map((item,index) => (
      <>
     

        <tr className="tr_separator">  
        
        <td>
        {item.id}
        </td>
        <td>
        <input type="text" defaultValue={item.name} name="name" onChange={e=>{
            const val = e.target.value;  
            setReturnedArray((prevState)=>{
                prevState[index].name = val; 
                return(prevState);
            })

            console.log(returnedArray);
        }} />
        </td>


        <td>
        
        <Checkbox isActive={item.canSelectOnlyOneFromThisGroup} size={40} colorActive="green" color="#000"
        onClickFunction={()=>{ 
      
      
          if(returnedArray[index].canSelectOnlyOneFromThisGroup === true){
            setReturnedArray((prevState)=>{ 
              prevState[index].canSelectOnlyOneFromThisGroup = false; 
              return(prevState); 
          })
          }else{
            setReturnedArray((prevState)=>{ 
              prevState[index].canSelectOnlyOneFromThisGroup = true; 
              return(prevState); 
          })
          }
          
      
        forceUpdate(); 
        }}
        />
        
        </td>
    
 
 


        <td><Button onClick={()=>handleItemEdit(index)}><CloudUpload size={30}  /></Button></td>
        <td><Link onClick={()=>deleteItem(item.id)}><XCircleFill size={30}  /></Link></td>
         
        </tr>
<tr>
<td className="table_header" colspan="5">
tagi w obrębie grupy:

{returnedArray[index].loadingAssociatedTags?(
  <Spinner animation="border" size="xs" variant="dark" className="main_spinner" />
):null}
</td>
</tr>


 



{returnedArray[index].associatedTagsArray.map((tagItem,tagItemIndex) => (
<>
<tr>
<td>{tagItem.id}</td>
<td colspan="2"> 
<input type="text" placeholder="Wpisz nazwę..." value={tagItem.name} onChange={e=>{
  var val = e.target.value;
  setReturnedArray((prevState)=>{ 
    prevState[index].associatedTagsArray[tagItemIndex].name = val; 
    return(prevState); 
});
forceUpdate();
}} />

</td> 
<td><Button onClick={()=>editTag(tagItemIndex, index)}><CloudUpload size={30}  /></Button></td> 
<td><Link onClick={()=>deleteTag(tagItemIndex, index)}><XCircleFill size={30}  /></Link></td>

</tr>
</>
))}

<tr>
<td colspan="5" style={{fontWeight: 'bold', fontSize: 10}}>dodaj nowy tag:</td> 
</tr>

<tr> 
<td colspan="3">
<input type="text" placeholder="Wpisz nazwę..." value={returnedArray[index].newTagName} onChange={e=>{
  var val = e.target.value;
  setReturnedArray((prevState)=>{ 
    prevState[index].newTagName = val; 
    return(prevState); 
});
forceUpdate();
}} />
</td>
<td><Button onClick={()=>newTag(index)}><CloudUpload size={30}  /></Button></td> 
<td>&nbsp;</td>
</tr>

</>
    ))}

<tr className="tr_add_new">
<td colspan="4">
<p>Dodaj nową grupę tagów</p>
</td>
</tr>

    <tr>  
        
    <td>
    &nbsp;
    </td>
    <td colspan="2">
    <input type="text" placeholder="Wpisz nazwę..." value={newItemName} onChange={e=>setNewItemName(e.target.value)} />
    </td>
  
    <td><Button onClick={()=>addNewItem()}><CloudUpload size={30}  /></Button></td> 
     
    </tr>

    </table> 
 
</div>
 

<div className="hr_separator"></div>

 {getAssociatedTagsFlag?(
<>
{getAllAssociatedTags_forEachLoop()}
</>
 ):null}

</>
    );
}