import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter,
    useParams
  } from "react-router-dom"; 
  import axios from "axios";
import {GLOBAL_apiPath, serwer_publicPath} from '../../config/GlobalSettings.js';
  import AppContext from '../../context.js';

//comp
import Checkbox from '../../components/Checkbox.js';

  //bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';

//bootstrap icones
import { XCircleFill, CloudUpload, Upload, LayersHalf, HandIndex, XSquare, PlusSquareFill, Folder } from 'react-bootstrap-icons';

  


export default function GamesEditContent(props){

  const params = useParams();
  const gameId = params.gameId;

    const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);
const [loadingFilesList, setLoadingFilesList] = useState(false);
const [loadingUploadingFile, setLoadingUploadingFile] = useState(false);
const [loadingAddNewContent, setLoadingAddNewContent] = useState(false);
const [returnedArray, setReturnedArray] = useState([]);
const [userFilesArray, setUserFilesArray] = useState([]);
const [uploadBlobFile, setUploadBlobFile] = useState(""); 
const [gameName, setGameName] = useState("");
const [availableTags, setAvailableTags] = useState([]);
const [showFilemanagerModal, setShowFilemanagerModal] = useState(false);
const [targetFilePathPasteObject, setTargetFilePathPasteObject] = useState("");
const [newGameContentObject, setNewGameContentObject] = useState({
  title:"",
  snipet:"",
  longDescription:"",
  headerImagePath:"",
  thumbnailImagePath:"",
  thumbnailVideoPath:"",
  technicalRequirements: [],
  galleryArray:[],
  versionsArray:[],
  assignedTags: []
});

const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);



const gameDoesntContainTag = (tagId) =>{

  var returnVal = true;

  newGameContentObject.assignedTags.forEach((row)=>{
if(row.id === tagId){
  returnVal = false;
}
  });

  return returnVal;
}


const gameContainTagFromThisGroup = (group_id) =>{
  var returnVal = false;

  newGameContentObject.assignedTags.forEach((row)=>{
 

    if(parseInt(row.groupId) === parseInt(group_id)){
      returnVal = true;
    }
      });

      return returnVal;
}


const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        setUploadBlobFile(binaryStr);
      }
      reader.readAsDataURL(file);
    })
    
  }, [])


  const pasteImagePath = (pathString) =>{
    setShowFilemanagerModal(false); 

if(targetFilePathPasteObject.includes("galleryItem_")){
  var splitted = targetFilePathPasteObject.split("_");
var galleryItemIndex = splitted[1];

setNewGameContentObject((prevState)=>{ 
  prevState.galleryArray[galleryItemIndex] = pathString; 
  return(prevState); 
})
forceUpdate(); 


}else if(targetFilePathPasteObject.includes("versionItem_")){
  var splitted = targetFilePathPasteObject.split("_");
  var versionItemIndex = splitted[1];
  
  setNewGameContentObject((prevState)=>{ 
    prevState.versionsArray[versionItemIndex].filePath = pathString; 
    return(prevState); 
  })
  forceUpdate(); 
}else{
  setNewGameContentObject((prevState)=>{ 
    prevState[targetFilePathPasteObject] = pathString; 
    return(prevState); 
})
forceUpdate(); 
}

 



  }



  const {acceptedFiles, getRootProps, getInputProps, isDragActive,  isDragAccept, isDragReject} = useDropzone({onDrop, accept: 'image/*,video/*', maxSize: 15000000});
  
 

const baseStyle = {  
  };



  const activeStyle = {
    background: '#10d123', borderColor:'#fff'
  };
   

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {})
  }), [
    isDragActive
  ]);




useEffect(() => { 
    getArrayAjax(); 
  }, []);






  const getFileListAjax = () =>{ 
    setLoadingFilesList(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash
    };
    axios.post(GLOBAL_apiPath+`user-files/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoadingFilesList(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setUserFilesArray(res.data.returnArray);
       }
    }).catch(err => {
      setLoadingFilesList(false); 
    })
  }


  const addNewFile = () =>{

    if(acceptedFiles.length>0){ 
      setLoadingUploadingFile(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        file: uploadBlobFile,
        fileName: acceptedFiles[0].path
    };
    axios.post(GLOBAL_apiPath+`user-files/create`, { obj })
    .then(res => {   
      setLoadingUploadingFile(false); 
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getFileListAjax();
       }
    }).catch(err => {
      setLoadingUploadingFile(false); 
    })
 
    }else{
        contextObject._runAlert("Wymagane dodanie pliku");
    }

  }














  const getArrayAjax = () =>{ 
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
        gameId: gameId
    };
    axios.post(GLOBAL_apiPath+`games-content/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoading(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setReturnedArray(res.data.returnArray);
         setGameName(res.data.returnGameObject.name);
         setAvailableTags(res.data.availableTags);
         console.log('tagi :');
         console.log(res.data.availableTags);
       }
    }).catch(err => {
      setLoading(false); 
    })
  }




  const deleteItem = (id) =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        rowId: id
    };
    axios.post(GLOBAL_apiPath+`games-content/delete`, { obj })
    .then(res => {  
    
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })
  }











  const handleItemEdit = (index, isApprovedByAdminState) =>{
    console.log('luj'); 
console.log(isApprovedByAdminState);
    setReturnedArray((prevState)=>{ 
      prevState[index].loading = true; 
      return(prevState); 
  })
forceUpdate(); 



setLoading(true);  
var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    isApprovedByAdmin: isApprovedByAdminState,
    rowId: returnedArray[index].id
};
axios.post(GLOBAL_apiPath+`games-content/edit`, { obj })
.then(res => {  

  setReturnedArray((prevState)=>{ 
    prevState[index].loading = false; 
    return(prevState); 
})
forceUpdate(); 


  if(res.data.authError === true){ 
    contextObject._logout();
   }else{
     //sukces! - pobieramy więc aktualną listę
     getArrayAjax();
   }
}).catch(err => {
  setReturnedArray((prevState)=>{ 
    prevState[index].loading = false; 
    return(prevState); 
})
forceUpdate(); 
})
  }









  const addNewItem = () =>{ 
    setLoadingAddNewContent(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        contentObject: newGameContentObject,
        gameId: gameId
    };
    axios.post(GLOBAL_apiPath+`games-content/create`, { obj })
    .then(res => {   
      setLoadingAddNewContent(false);
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoadingAddNewContent(false); 
    })
 

  }


    return(
<>



{showFilemanagerModal?(
<>

<div className="fixed_modal_bg">
</div>


<div className="main_modal_overall">
<div className="main_modal">
<div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
<p className="modal_title">Pliki</p>

<Link onClick={()=>{
  setShowFilemanagerModal(false);
}}>
<XSquare size={20} color="#5528e5" />
</Link>
</div>


<p style={{
  color: "#1f0573",
  fontSize: 15
}}>Twoje pliki:


{loadingFilesList? <Spinner animation="border" size="sm" variant="dark" className="main_spinner" /> : null} 


</p>

<div style={{
  overflow: 'auto', maxHeight: 600
}}>


{userFilesArray.map((fileItem,fileIndex) => (
  <Button className="main_input flex-center-center"
  onClick={()=>{
    pasteImagePath(fileItem.filePath);
  }}
  >

  {fileItem.isImage ?(
<img src={serwer_publicPath+fileItem.filePath} style={{height: 50, marginRight: 50}} />
  ):null}

  <p>{fileItem.name}</p>
  </Button> 
))}


</div>



<p style={{
  color: "#1f0573",
  fontSize: 15
}}>Dodaj nowy:</p>


<div style={{
  display: 'flex', justifyContent: 'center', alignItems: 'center'
}}>

<div {...getRootProps({className: 'dropzone dropzone-in-modal', style: style})}>
        <input {...getInputProps()} />
        <Upload />
      </div>


<Button className="main_btn" style={{paddingBottom: 20, paddingTop: 20, marginLeft: 5, width: '25%'}}
onClick={()=>addNewFile()}
>
{loadingUploadingFile? <Spinner animation="border" size="sm" variant="light" className="main_spinner" /> : "wgraj"} 
</Button>


      </div>
    

      {acceptedFiles.length>0? acceptedFiles[0].path:null}

      <br /><br /> 


</div>
</div>


</>
):null}










<h1 className="panel_page_title">
Edycja gry
{loading? <Spinner animation="border" size="md" variant="dark" className="main_spinner" /> : null} 
</h1>

<p>nazwa gry w panelu: <b>{gameName}</b></p>


{returnedArray.length!==0?(
<>


<p style={{
  color: '#1f0573', fontSize: 20, fontWeight: 'bold'
}}>
Wersjonowanie treści
</p>
<br />
<span>
Każdorazowo po wprowadzeniu zmian (stworzeniu nowej wersji) administrator musi zaakceptować modyfikacje



{contextObject.AUTH_userRange === "global_admin" ?(
  <>
  <br /><br />
<b>uwaga! ze względu na rozbudowaną strukturę, relacja gry z przypisanymi do niej tagami odbywa się w momencie zaakceptowania treści przez admina.
Jednocześnie portal każdorazowo prezentuje najnowszą zaakceptowaną treść, tym samym nie powinno się akceptować wersji treści starszych niż najnowsza zaakceptowana.
</b>
</>
):null}
</span>

<div className="table_overall">
  
    <table>
    <tr>
    <td>id</td>
    <td>data utworzenia</td>
    <td>zaakceptowana<br />przez<br />administratora</td>
    <td>wczytaj<br />do edycji</td>
    <td>&nbsp;</td>
    </tr>
    {returnedArray.map((item,index) => (
        <tr>  
        
        <td>
        {item.id}
        </td>
        <td>
         {item.createDate}
        </td>
        <td>
        {contextObject.AUTH_userRange === "global_admin" ?(
          <>
          {item.loading?(
            <Spinner animation="border" size="sm" variant="dark" className="main_spinner" />
          ):(
            <Checkbox isActive={item.isApprovedByAdmin} size={40} colorActive="green" color="#000"
            onClickFunction={()=>{ 
          
          
              if(returnedArray[index].isApprovedByAdmin === true){ 
                setReturnedArray((prevState)=>{ 
                  prevState[index].isApprovedByAdmin = false;  
                  return(prevState); 
              })
              handleItemEdit(index, false);
              }else{
                setReturnedArray((prevState)=>{ 
                  prevState[index].isApprovedByAdmin = true; 
                  return(prevState); 
              })
              handleItemEdit(index, true);
              }
              
          
            forceUpdate(); 
            
            }}
            /> 
          )}
         
          </>
        ):(
          <Checkbox isActive={item.isApprovedByAdmin} size={40} colorActive="green" color="#000" 
          />
        )}

        
        </td>
        <td><Button onClick={()=>setNewGameContentObject(returnedArray[index].contentObject)}><HandIndex /></Button></td>
        <td><Link onClick={()=>deleteItem(item.id)}><XCircleFill size={30}  /></Link></td>
         
        </tr>
    ))}
 

    </table> 
 
</div>
</>
):null}

<div className="hr_separator"></div>

 


<p>

{returnedArray.length!==0? "Aktualizuj" : "Uzupełnij"}

&nbsp;treść
</p>




<form onSubmit="">


<p className="p_over_input">Tytuł gry</p>
<input type="text" className="main_input"
value={newGameContentObject.title}
onChange={(e)=>{
  const val = e.target.value;  
  setNewGameContentObject((prevState)=>{ 
    prevState.title = val; 
    return(prevState); 
})
forceUpdate(); 
}}
/>


<p className="p_over_input">Snipet (krótki opis)</p>
<textarea className="main_input"
value={newGameContentObject.snipet}
onChange={(e)=>{
  const val = e.target.value;  
  setNewGameContentObject((prevState)=>{ 
    prevState.snipet = val; 
    return(prevState); 
})
forceUpdate(); 
}}
/>


<p className="p_over_input">Długi opis</p>
<textarea className="main_input"
value={newGameContentObject.longDescription}
onChange={(e)=>{
  const val = e.target.value;  
  setNewGameContentObject((prevState)=>{ 
    prevState.longDescription = val; 
    return(prevState); 
})
forceUpdate(); 
}}
/>


<div className="hr_separator"></div>



<p className="p_over_input">Obraz w nagłówku</p>
<Button className="main_input flex-center-center" onClick={()=>{
  setTargetFilePathPasteObject("headerImagePath");
  getFileListAjax();
  setShowFilemanagerModal(true);
}}>

{newGameContentObject.headerImagePath != "" ?(
  <img src={serwer_publicPath+newGameContentObject.headerImagePath} style={{height: 50, marginRight: 50}} />
  ):(
    <Folder size={40} color="#5b1de4" />
  )}


<p>{newGameContentObject.headerImagePath}</p>
</Button>




<p className="p_over_input">Obraz widoczny na listingu (thumbnail)</p>
<Button className="main_input flex-center-center" onClick={()=>{
  setTargetFilePathPasteObject("thumbnailImagePath");
  getFileListAjax();
  setShowFilemanagerModal(true);
}}>

{newGameContentObject.thumbnailImagePath != "" ?(
  <img src={serwer_publicPath+newGameContentObject.thumbnailImagePath} style={{height: 50, marginRight: 50}} />
):(
  <Folder size={40} color="#5b1de4" />
)}

<p>{newGameContentObject.thumbnailImagePath}</p>
</Button>


<p className="p_over_input">Wideo widoczne na listingu (hover thumbnail)</p>
<Button className="main_input" onClick={()=>{
  setTargetFilePathPasteObject("thumbnailVideoPath");
  getFileListAjax();
  setShowFilemanagerModal(true);
}}>

{newGameContentObject.thumbnailVideoPath == "" ? (
  <Folder size={40} color="#5b1de4" />
):(
  <>
  {newGameContentObject.thumbnailVideoPath}
  </>
)}
</Button>



<div className="hr_separator"></div>

<p className="p_over_input">Galeria</p><br/>

{newGameContentObject.galleryArray.map((galleryItem, galleryItemIndex) => (
  <>

<div className="flex-center-center">
  <Button className="main_input flex-center-center" 
  style={{width: '80%', marginRight: 10}}
  onClick={()=>{
    setTargetFilePathPasteObject("galleryItem_"+galleryItemIndex);
    getFileListAjax();
    setShowFilemanagerModal(true);
  }}>
   
  {galleryItem != ""?(
    <img src={serwer_publicPath+galleryItem} style={{height: 50, marginRight: 50}} />
  ):(
    <Folder size={40} color="#5b1de4" />
  )}
   
 
  
  <p>{galleryItem}</p>
  </Button>

  <Link onClick={()=>{
    setNewGameContentObject((prevState)=>{ 
          
        prevState.galleryArray.splice(galleryItemIndex,1); 
      
      return(prevState); 

  }) 
  }}><XCircleFill style={{marginTop: -20}} size={30} />
  </Link>

  </div>
  </>
  ))}

  <div className="flex-center-center" style={{width: '100%'}}>
  <Link onClick={()=>{
     //bez locka dodawały się 2x itemy do galerii na raz...
    var lock = false;
    if(newGameContentObject.galleryArray[newGameContentObject.galleryArray.length-1] != ""){
      setNewGameContentObject((prevState)=>{ 
         
        if(lock === false){
          prevState.galleryArray.push("");
          lock = true;
        }
        
        return(prevState); 

    }) 
    }else{
      contextObject._runAlert("Wybierz obraz dla ostatniej komórki przed dodaniem kolejnej");
    }
   

  }}>
  <PlusSquareFill color="#5b1de4" size={40} style={{marginTop:20}} />
  </Link>
  </div>
<div className="hr_separator"></div>




<p className="p_over_input">Wymagania techniczne</p><br/>
<div className="table_overall">
<table> 
<tr>
<td>jednostka<br /><i>np. [GB]</i></td>
<td>wartość<br /><i>[liczba całkowita]</i></td>
<td>opis<br /><i>opcjonalny</i></td>
<td>&nbsp;</td>
</tr>

{newGameContentObject.technicalRequirements.map((technicalRequirementsItem, technicalRequirementsItemIndex) => (

<tr>
<td>
<input type="text" value={technicalRequirementsItem.unit} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.technicalRequirements[technicalRequirementsItemIndex].unit = val.toUpperCase();
    return(prevState); 
}) 
forceUpdate();
}} />
</td>

<td>
<input type="number" value={technicalRequirementsItem.value} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.technicalRequirements[technicalRequirementsItemIndex].value = val;
    return(prevState); 
}) 
forceUpdate();
}} />
</td>

<td>
<textarea className="main_input" type="text" value={technicalRequirementsItem.description} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.technicalRequirements[technicalRequirementsItemIndex].description = val;
    return(prevState); 
}) 
forceUpdate();
}} />
</td>
<td>
<Link onClick={()=>{
  setNewGameContentObject((prevState)=>{ 
        
      prevState.technicalRequirements.splice(technicalRequirementsItemIndex,1); 
    
    return(prevState); 

}) 
}}><XCircleFill style={{marginTop: -20}} size={30} />
</Link>
</td>
</tr>

  ))}
  </table>
  </div>

  <div className="flex-center-center" style={{width: '100%'}}>
  <Link onClick={()=>{
     //bez locka dodawały się 2x itemy do galerii na raz...
    var lock = false; 
      setNewGameContentObject((prevState)=>{ 
         
        if(lock === false){
          prevState.technicalRequirements.push({
            unit: "",
            value: 0,
            description: ""
          });
          lock = true;
        }
        
        return(prevState); 

    }) 
   
   

  }}>
  <PlusSquareFill color="#5b1de4" size={40} style={{marginTop:20}} />
  </Link>
  </div>




<div className="hr_separator"></div>














 



<p className="p_over_input">Wersje gry</p><br/>
<div className="table_overall">
<table> 
<tr>
<td>nazwa wersji</td>
<td>rodzaj</td>
<td>link/plik</td> 
<td>cena sprzedaży [PLN]</td>
<td>opis</td>
<td>&nbsp;</td>
</tr>

{newGameContentObject.versionsArray.map((versionItem, versionItemIndex) => (

<tr>
<td>
<input type="text" value={versionItem.name} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.versionsArray[versionItemIndex].name = val;
    return(prevState); 
}) 
forceUpdate();
}} />
</td>

<td>

<Dropdown className="main_dropdown">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
  {(() => {switch(versionItem.type){
    case 'file':
        return "plik";
        break;
        case 'link':
            return "link zewnętrzny";
            break; 
}
})()}
  </Dropdown.Toggle>

  <Dropdown.Menu> 
    <Dropdown.Item onClick={()=>{ 
      setNewGameContentObject((prevState)=>{  
        prevState.versionsArray[versionItemIndex].type = "file";
      return(prevState); 
  }) 
  forceUpdate();

    }}>
    
    plik
    </Dropdown.Item>

    <Dropdown.Item onClick={()=>{ 
      setNewGameContentObject((prevState)=>{  
        prevState.versionsArray[versionItemIndex].type = "link";
      return(prevState); 
  }) 
  forceUpdate();

    }}>
    
    link zewnętrzny
    </Dropdown.Item>

 
  </Dropdown.Menu>
</Dropdown>

</td>






<td>
{versionItem.type === "link"?(
  <input type="text" value={versionItem.link} onChange={(e)=>{
    const val = e.target.value;
    setNewGameContentObject((prevState)=>{  
        prevState.versionsArray[versionItemIndex].link = val;
      return(prevState); 
  }) 
  forceUpdate();
  }} />
):(
  <>
  <Button className="main_input flex-center-center" 
  style={{width: '100%', marginRight: 0}}
  onClick={()=>{
    setTargetFilePathPasteObject("versionItem_"+versionItemIndex);
    getFileListAjax();
    setShowFilemanagerModal(true);
  }}>
   
  <Folder size={40} color="#5b1de4" />

  </Button>

  {versionItem.filePath != ""?(
    <input type="text" disabled={true} value={serwer_publicPath+versionItem.filePath} />
  ):null}


</>
)}

</td>


<td>
<input type="number" step="0.01" value={versionItem.price100/100} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.versionsArray[versionItemIndex].price100 = val*100;
    return(prevState); 
}) 
forceUpdate();
}} />
</td>
 

<td>
<textarea className="main_input" type="text" value={versionItem.description} onChange={(e)=>{
  const val = e.target.value;
  setNewGameContentObject((prevState)=>{  
      prevState.versionsArray[versionItemIndex].description = val;
    return(prevState); 
}) 
forceUpdate();
}} />
</td>



<td>
<Link onClick={()=>{
  setNewGameContentObject((prevState)=>{ 
        
      prevState.versionsArray.splice(versionItemIndex,1); 
    
    return(prevState); 

}) 
}}><XCircleFill style={{marginTop: -20}} size={30} />
</Link>
</td>
</tr>

  ))}
  </table>
  </div>

  <div className="flex-center-center" style={{width: '100%'}}>
  <Link onClick={()=>{
     //bez locka dodawały się 2x itemy do galerii na raz...
    var lock = false; 
      setNewGameContentObject((prevState)=>{ 
         
        if(lock === false){
          prevState.versionsArray.push({
            name:"",
            price100:0,
            link:"",
            filePath:"",
            description: "",
            type:"file"
          });
          lock = true;
        }
        
        return(prevState); 

    }) 
   
   

  }}>
  <PlusSquareFill color="#5b1de4" size={40} style={{marginTop:20}} />
  </Link>
  </div>




<div className="hr_separator"></div>































<p className="p_over_input">Dostępne tagi:</p><br/>
<div className="table_overall">
<table> 
<tr>
<td>nazwa</td> 
<td>przypisz tag</td>
</tr>

{availableTags.map((tagGroupItem, tagGroupItemIndex) => (
<>
<tr>
<td colspan="2" className="table_header">
grupa: {tagGroupItem.name}

<br />
<span style={{fontStyle: 'italic', fontWeight: 'normal'}}>
{tagGroupItem.canSelectOnlyOneFromThisGroup ? "Możliwe dodanie tylko jednego tagu z tej grupy" : "Możliwe dodanie wielu tagów z tej grupy"}
</span>


</td>
</tr>


{availableTags[tagGroupItemIndex].tagsFromGroupArray.length === 0 ? (
<tr>
<td colspan="2">brak tagów w obrębie grupy</td>
</tr>
):(
  <>
  {availableTags[tagGroupItemIndex].tagsFromGroupArray.map((tagItem, tagItemIndex) => ( 
    <tr>
    
    <td>
    {tagItem.name}
    </td>
    
    <td>


    {gameDoesntContainTag(tagItem.id) ? (
      <>


      {gameContainTagFromThisGroup(tagGroupItem.id) == true && tagGroupItem.canSelectOnlyOneFromThisGroup == true ? null:(
        <Link onClick={()=>{
          //bez locka dodawały się 2x itemy do galerii na raz...
         var lock = false; 
           setNewGameContentObject((prevState)=>{ 
              
             if(lock === false){
               prevState.assignedTags.push({
                 id:tagItem.id,
                 name: tagItem.name,
                 groupName: tagGroupItem.name,
                 groupId: tagItem.groupId,
                 groupSettings_canSelectOnlyOneFromThisGroup: tagGroupItem.canSelectOnlyOneFromThisGroup
               });
               lock = true;
             }
             
             return(prevState); 
     
         }) 
        
        
     
       }}>
       <PlusSquareFill color="#5b1de4" size={30} style={{marginTop:20}} />
       </Link>
      )}




     </>
    ):null}



    </td>
    
    </tr> 
    ))}

  </>
)}





</>

  ))}



</table>
</div>

















<div className="hr_separator"></div>




<p className="p_over_input">Przypisane tagi do gry:</p><br/>
<div className="table_overall">
<table> 
<tr>
<td>nazwa tagu</td>
<td>nazwa grupy</td> 
<td>&nbsp;</td>
</tr>

{newGameContentObject.assignedTags.map((assignedTagItem, assignedTagItemIndex) => (
 <tr>

 <td>
 {assignedTagItem.name}
 </td>

 <td>
{assignedTagItem.groupName}
</td>

<td>
<Link onClick={()=>{
  var lock = false;
  setNewGameContentObject((prevState)=>{ 
        

        if(lock === false){
      prevState.assignedTags.splice(assignedTagItemIndex,1); 
      lock = true;
        }
    return(prevState); 

}) 
}}><XCircleFill style={{marginTop: -20}} size={30} />
</Link>
</td>
</tr>

  ))}
  </table>
  </div>
 























<Button className="main_btn" style={{width: '100%', height: 60, marginTop: 50}}
onClick={()=>addNewItem()}
>
{loadingAddNewContent? <Spinner animation="border" size="sm" variant="light" className="main_spinner" /> : "dodaj"} 
</Button>

</form>







</>
    );
}