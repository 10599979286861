import React, { useContext, useState } from 'react';  
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import axios from "axios";
import {GLOBAL_apiPath} from '../config/GlobalSettings.js';
import AppContext from '../context.js';

//bootstrap
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

//bootstrap icones
import { XSquare } from 'react-bootstrap-icons';
 

//imgs
import img_playstrictMainLogo from '../assets/img/logo_color_main.png';
import img_mainSliderSample from '../assets/img/main_slider_sample.png';
import img_arrowLeftWhite from '../assets/img/arrow_left_white.png';
import img_arrowRightWhite from '../assets/img/arrow_right_white.png';
import img_blogSampleImg from '../assets/img/blog_sample_img.png';
import img_footerImg from '../assets/img/footer_img.png';
import img_gameCoverSample from '../assets/img/game_cover_sample.png';
import img_gradientBg from '../assets/img/gradient_bg.png';
import img_icoAppStore from '../assets/img/ico_app_store.png';
import img_icoGooglePlay from '../assets/img/ico_google_play.png';
import img_leftArrowCircle from '../assets/img/ico_left_arrow_circle.png';
import img_rightArrowCircle from '../assets/img/ico_right_arrow_circle.png';
import img_logoColorMini from '../assets/img/logo_color_mini.png';
import img_logoWhiteMini from '../assets/img/logo_white_mini.png';
import img_icoPlay from '../assets/img/play_ico.png';
import img_icoSearch from '../assets/img/ico_search.png';
import img_icoPlusCircle from '../assets/img/plus_circle_ico.png';
import img_sliderSampleImg from '../assets/img/slider_sample_img.png';
import img_socialIcons from '../assets/img/social_icons.png';

export default function RegisterModal(props) {
 
  const [wrongEmailFormat, setWrongEmailFormat] = useState(false);
const [anyValidationErrors, setAnyValidationErrors] = useState(false);
const [loading, setLoading] = useState(false);
 
const contextObject = useContext(AppContext);

  const checkEmailSyntax = (e) =>{ 

    var inputVal = e.target.value;
    
    if(validateEmail(inputVal)){
      setWrongEmailFormat(false);
      setAnyValidationErrors(false);
    }else{
      setWrongEmailFormat(true);
      setAnyValidationErrors(true);
    }
  }

 const validateEmail = (email)=> {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


 


const handleRegister= (e) =>{
  e.preventDefault(); 


  if(e.target['nick'].value.length<=3 || e.target['nick'].value.length>=30){
    contextObject._runAlert("Nick powinien zawierać od 3 do 30 znaków");
  }else{
    if(e.target['password1'].value === e.target['password2'].value){
      setLoading(true); 

      var obj = {
        email: e.target['email'].value,
        password: e.target['password1'].value,
        nick: e.target['nick'].value,
        range: "developer"
      };
      axios.post(GLOBAL_apiPath+`auth/register`, { obj })
      .then(res => { 
         console.log(res.data);
         setLoading(false); 
    
        if(res.data.success === true){ 
          contextObject._runAlert("Zarejestrowano konto, sprawdź swoją skrzynkę e-mail, aby zweryfikować adres.");
          props.closeFunction();
         }else{
          
          if(res.data.verifiedUserWithThisEmailExist){
            contextObject._runAlert("Ten adres e-mail jest już zajęty");
          }
    
          if(res.data.nickAlreadyTaken){
            contextObject._runAlert("Ten nick jest już zajęty");
          }
    
    
    
          
         }
      }).catch(err => {
        setLoading(false); 
      })
    }else{
      contextObject._runAlert("Wprowadzone hasła nie są identyczne");
    }

  }



}
 
  return ( 
    <>
 
 
    <div className="fixed_modal_bg" ></div>
     
    <div className="main_modal_overall">
    <div className="main_modal">
    <div className="main_modal_overflow">
    <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
    <p className="modal_title">Witamy w playstict!</p>
    
    <Link onClick={()=>{
       props.closeFunction();
    }}>
    <XSquare size={20} color="#5528e5" />
    </Link>
    </div>
    
    <form onSubmit={(e)=>handleRegister(e)}>
    <p className="p_over_input">Twój nick</p>
    <input type="text" placeholder="Od 3 do 30 znaków" className="main_input" name="nick" />
    
    <p className="p_over_input">Adres e-mail 
    {wrongEmailFormat ? <span className="validate_error">&nbsp;nieprawidłowy format!</span> : null}
    </p>
    <input type="text" placeholder="" className="main_input" name="email" onChange={e=>checkEmailSyntax(e)} />
    
    <p className="p_over_input">Hasło 
    </p>
    <input type="password" placeholder="" className="main_input" name="password1" />
    
    <p className="p_over_input">Powtórz hasło 
    </p>
    <input type="password" placeholder="" className="main_input" name="password2"   />
    
    {anyValidationErrors?null:(
      <Button 
      type="submit" 
      className="main_btn" style={{width: '90%', marginLeft: '5%', marginTop: 20, marginBottom: 20, borderRadius: 10, paddingTop: 11, paddingBottom: 11}}>
      {loading?(
        <Spinner animation="border" size="sm" variant="light" />
      ):"Załóż konto"} 
      </Button>
    )}
 
    </form>
    
    <div className="hr_separator_modal"></div>
    
    <p className="modal_footer_p">
    <Link onClick={()=>props.showLoginModalFunction()}>Zaloguj się </Link>
    jeśli posiadasz już konto Playstrict
    </p>
    
    </div>
    </div>
    </div>
    </>
  );
} 
 