import React, { useState, useEffect, useContext } from 'react';

 

import axios from "axios";
import {GLOBAL_apiPath, playstrict_platformPath} from '../config/GlobalSettings.js';
import AppContext from '../context.js';
import { 
  Link
} from "react-router-dom"; 
//bootstrap
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

//bootstrap icones
import { XSquare, HeartFill, ArrowLeftCircleFill, ArrowRightCircleFill, ChatDotsFill, ExclamationCircleFill, Search } from 'react-bootstrap-icons';
 



//imgs
import img_playstrictMainLogo from '../assets/img/logo_color_main.png';
import img_mainSliderSample from '../assets/img/main_slider_sample.png';
import img_arrowLeftWhite from '../assets/img/arrow_left_white.png';
import img_arrowRightWhite from '../assets/img/arrow_right_white.png';
import img_blogSampleImg from '../assets/img/blog_sample_img.png';
import img_footerImg from '../assets/img/footer_img.png';
import img_gameCoverSample from '../assets/img/game_cover_sample.png';
import img_gradientBg from '../assets/img/gradient_bg.png';
import img_icoAppStore from '../assets/img/ico_app_store.png';
import img_icoGooglePlay from '../assets/img/ico_google_play.png';
import img_leftArrowCircle from '../assets/img/ico_left_arrow_circle.png';
import img_rightArrowCircle from '../assets/img/ico_right_arrow_circle.png';
import img_logoColorMini from '../assets/img/logo_color_mini.png';
import img_logoWhiteMini from '../assets/img/logo_white_mini.png';
import img_icoPlay from '../assets/img/play_ico.png';
import img_icoSearch from '../assets/img/ico_search.png';
import img_icoPlusCircle from '../assets/img/plus_circle_ico.png';
import img_sliderSampleImg from '../assets/img/slider_sample_img.png';
import img_socialIcons from '../assets/img/social_icons.png';
import img_sampleGameDetailsBg from '../assets/img/sample_game_details_bg.png';
import img_sampleAvatar from '../assets/img/sample_avatar.jpg';
import img_europeanFund from '../assets/img/FE_PW.jpg';

 

 

 

export default function Footer(props) {
 


  return (   
 
    <> 
  

<Row style={{
  background: '#5528e5',
  alignItems: 'center',
  justifyContent: 'center',
  height: 200
}}

>

<a href="#">
<img src={img_socialIcons} />
</a>

</Row>







<Row className="footer">
<Container>

<div style={{height: 50, width: '100%'}}></div>


<Row>
<Col xl={3} lg={3} md={6} sm={12} xs={12}>
<img src={img_logoWhiteMini} />
<p style={{marginTop: 30, fontSize: 13}}>ul. Władysława Trylińskiego 2<br />
10-683 Olsztyn<br />
Tel: +12 458799999<br />
info@playstrict.io</p>
</Col>

<Col xl={2} lg={2} md={6} sm={12} xs={12}> 
<p className="column_name">First column</p>


  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>

</Col>


<Col xl={2} lg={2} md={6} sm={12} xs={12}> 
<p className="column_name">Second column</p>


  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>

</Col>


<Col xl={2} lg={2} md={6} sm={12} xs={12}> 
<p className="column_name">Third column</p>


  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>
  <Link>Home</Link>

</Col>



<Col xl={3} lg={3} md={6} sm={12} xs={12}> 
<p className="column_name">Aplikacja mobilna</p>
 
 <img src={img_icoGooglePlay} style={{display: 'block', marginTop: 10}}/>
 <img src={img_icoAppStore} style={{display: 'block', marginTop: 10}}/>
 
</Col>
</Row>


<div style={{height: 50, width: '100%'}}></div>



{/* separator HR */}
<div style={{width: '100%', height: 1, background: '#615c70'}}></div>
{/* separator HR */}


<div style={{
  display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 12, marginTop: 10, marginBottom: 10
}}>
<p>&copy; 2020 Playstrict. All right reserved.</p>
<a href={playstrict_platformPath} target="_blank">Playstrict</a>
<Link>Polityka prywatności</Link>
</div>

<div style={{
  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10
}}>
<a href="https://blog.playstrict.com/UE" target="_blank">
<img src={img_europeanFund} />
</a>
</div>

</Container>

</Row>

</>
  
  );
} 

 