import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom"; 
  import axios from "axios";
import {GLOBAL_apiPath, serwer_publicPath} from '../../config/GlobalSettings.js';
  import AppContext from '../../context.js';

//comp
import Checkbox from '../../components/Checkbox.js';

  //bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';

//bootstrap icones
import { XCircleFill, CloudUpload, Upload, GearFill } from 'react-bootstrap-icons';
 


export default function SlidersList(props){ 
    const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);
const [returnedArray, setReturnedArray] = useState([]); 
const [newItemName, setNewItemName] = useState(""); 

const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);



useEffect(() => { 
    getArrayAjax();
  }, []);





  const getArrayAjax = () =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
    };
    axios.post(GLOBAL_apiPath+`sliders/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoading(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setReturnedArray(res.data.returnArray); 
       }
    }).catch(err => {
      setLoading(false); 
    })
  }




  const deleteItem = (id) =>{
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        rowId: id
    };
    axios.post(GLOBAL_apiPath+`sliders/delete`, { obj })
    .then(res => {  
    
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })
  }











  const handleItemEdit = (index) =>{ 

setLoading(true);  
var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    name: returnedArray[index].name,
    rowId: returnedArray[index].id
};
axios.post(GLOBAL_apiPath+`sliders/edit`, { obj })
.then(res => {  
console.log(res.data);

  if(res.data.authError === true){ 
    contextObject._logout();
   }else{
     //sukces! - pobieramy więc aktualną listę
     getArrayAjax();
   }
}).catch(err => {
  setLoading(false); 
})
  }









  const addNewItem = () =>{

    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
        name: newItemName
    };
    axios.post(GLOBAL_apiPath+`sliders/create`, { obj })
    .then(res => {  
    setNewItemName("");
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getArrayAjax();
       }
    }).catch(err => {
      setLoading(false); 
    })

  }


    return(
<>
<h1 className="panel_page_title">
Lista sliderów
{loading? <Spinner animation="border" size="md" variant="dark" className="main_spinner" /> : null} 
</h1>

<p>
po dodaniu slidera, należy go również zakodować w szablonie posługując się numerem id
</p>

<div className="table_overall">
  
    <table>
    <tr>
    <td>id</td>
    <td>nazwa<br />w panelu</td> 
    <td>edycja slide'ów</td>
    <td>zapis nazwy</td>
    <td>&nbsp;</td>
    </tr>
    {returnedArray.map((item,index) => (
        <tr>  
        
        <td>
        {item.id}
        </td>
        <td>
        <input type="text" defaultValue={item.name} name="name" onChange={e=>{
            const val = e.target.value;  
            setReturnedArray((prevState)=>{
                prevState[index].name = val;
                console.log('prev stejt: ');
                console.log(prevState);
                return(prevState);
            })

            console.log(returnedArray);
        }} />
        </td>  
        <td><Link to={"/panel/sliders/edit-content/"+item.id}><Button className="main_btn"><GearFill /> <span>edycja</span></Button></Link></td>
        <td><Button onClick={()=>handleItemEdit(index)}><CloudUpload size={30} /></Button></td>
        <td><Link onClick={()=>deleteItem(item.id)}><XCircleFill size={30}  /></Link></td>
         
        </tr>
    ))}

<tr className="tr_add_new">
<td colspan="5">
<p>Dodaj nowy slider</p>
</td>
</tr>

    <tr>  
        
    <td>
    &nbsp;
    </td>
    <td colspan="2">
    <input type="text" placeholder="Wpisz nazwę..." value={newItemName} onChange={e=>setNewItemName(e.target.value)} />
    </td>
   
    <td><Button onClick={()=>addNewItem()}><CloudUpload size={30} /></Button></td>
    <td>&nbsp;</td>
     
    </tr>

    </table> 
 
</div>
 

<div className="hr_separator"></div>

 

</>
    );
}