import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import AppContext from './context.js';


//pages
import HomePage from './pages/HomePage.js';
import PanelStack from './pages/panel/PanelStack.js';


//modals
import LoginModal from './modals/LoginModal.js';
import RegisterModal from './modals/RegisterModal.js'; 

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";

export default class App extends Component{

  constructor(props) {
    super(props);
    this.state = { 
      contextObject: { 
        AUTH_userEmail: "",
        AUTH_userHash: "",
        AUTH_userNick: "",
        AUTH_userRange: "",
        _set_AUTH_userEmail: (email)=>{this.state.contextObject.AUTH_userEmail = email; this.forceUpdate()},
        _set_AUTH_userHash: (hash)=>{this.state.contextObject.AUTH_userHash = hash; this.forceUpdate()},
        _set_AUTH_userNick: (nick)=>{this.state.contextObject.AUTH_userNick = nick; this.forceUpdate()},
        _set_AUTH_userRange: (range)=>{this.state.contextObject.AUTH_userRange = range; this.forceUpdate()},
        _logout: ()=>{this.logoutAction()},
        _runAlert: (val)=>{this.runAlertAction(val)}
        
      },
      homepageRedirect: false,
      showRegisterModal: false,
      showLoginModal: false,
    };
}



componentWillMount(){
  if(localStorage.getItem('AUTH_userHash') != "" && localStorage.getItem('AUTH_userHash') != undefined){
    //pobieranie zapisanych danych AUTH
    this.state.contextObject.AUTH_userEmail = localStorage.getItem('AUTH_userEmail');
    this.state.contextObject.AUTH_userHash = localStorage.getItem('AUTH_userHash');
    this.state.contextObject.AUTH_userNick = localStorage.getItem('AUTH_userNick');
    this.state.contextObject.AUTH_userRange = localStorage.getItem('AUTH_userRange');  
    this.forceUpdate();
  }
}


showLoginModal = () =>{
  this.setState({
    showRegisterModal: false,
    showLoginModal: true,
  });
}

showRegisterModal = () =>{
  this.setState({
    showRegisterModal: true,
    showLoginModal: false,
  });
}



runAlertAction = (val) =>{
alert(val);
}

logoutAction = () =>{ 

  localStorage.setItem('AUTH_userEmail', "");
  localStorage.setItem('AUTH_userHash', "");
  localStorage.setItem('AUTH_userNick', "");
  localStorage.setItem('AUTH_userRange', "");

this.state.contextObject.AUTH_userEmail = "";
this.state.contextObject.AUTH_userHash = "";
this.state.contextObject.AUTH_userNick = "";
this.state.contextObject.AUTH_userRange = ""; 
this.state.homepageRedirect = true;
this.forceUpdate();
};

 




render() { 

  return (
<>
<AppContext.Provider value={this.state.contextObject}>
<Router>

 

{this.state.homepageRedirect ?(
  <Redirect to="/" />
  ):null}


{this.state.showRegisterModal ?(
  <RegisterModal closeFunction={()=>this.setState({showRegisterModal:false})} showLoginModalFunction={()=>this.showLoginModal()} />
 
 ):null}
 
 
 
  
 
 
 
 {this.state.showLoginModal ?(
   <LoginModal closeFunction={()=>this.setState({showLoginModal:false})} showRegisterModalFunction={()=>this.showRegisterModal()} />
 
 ):null}







  


<Switch>


{this.state.contextObject.AUTH_userHash == "" ? (
  <Route path="/">
  <HomePage showLoginModalFunction={()=>this.showLoginModal()} showRegisterModalFunction={()=>this.showRegisterModal()} />
  </Route>
):(
  <>
  <Route exact path="/">
  <Redirect to="/panel/games" />
  </Route>

  <Route path="/panel">
  <PanelStack />
  </Route>
  </>
)}

 


</Switch>
</Router>
</AppContext.Provider>
</>
  );
}
} 
