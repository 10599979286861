import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter,
    useParams
  } from "react-router-dom"; 
  import axios from "axios";
import {GLOBAL_apiPath, serwer_publicPath} from '../../config/GlobalSettings.js';
  import AppContext from '../../context.js';

//comp
import Checkbox from '../../components/Checkbox.js';

  //bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

//bootstrap icones
import { XCircleFill, CloudUpload, Upload, LayersHalf, HandIndex, XSquare, PlusSquareFill, Folder } from 'react-bootstrap-icons';

  


export default function SlidersEditContent(props){

  const params = useParams();
  const sliderId = params.sliderId;

  const sampleSlideObject = {title: "", description: "", imagePath: ""};

  const [sliderObject, setSliderObject] = useState({
    name: "wsza",
    contentObject: [ 
    ]
  });
    const contextObject = useContext(AppContext);
const [loading, setLoading] = useState(false);
const [loadingFilesList, setLoadingFilesList] = useState(false);
const [loadingUploadingFile, setLoadingUploadingFile] = useState(false);
const [loadingAddNewContent, setLoadingAddNewContent] = useState(false);
const [returnedArray, setReturnedArray] = useState([]);
const [userFilesArray, setUserFilesArray] = useState([]);
const [uploadBlobFile, setUploadBlobFile] = useState(""); 
const [gameName, setGameName] = useState("");
const [showFilemanagerModal, setShowFilemanagerModal] = useState(false);
const [targetFilePathPasteSlideIndex, setTargetFilePathPasteSlideIndex] = useState("");

const [newGameContentObject, setNewGameContentObject] = useState({
  title:"",
  snipet:"",
  longDescription:"",
  headerImagePath:"",
  thumbnailImagePath:"",
  thumbnailVideoPath:"",
  technicalRequirements: [],
  galleryArray:[]
});

const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);



const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        setUploadBlobFile(binaryStr);
      }
      reader.readAsDataURL(file);
    })
    
  }, [])


  const pasteImagePath = (pathString) =>{ 
  setSliderObject((prevState)=>{ 
    prevState.contentObject[targetFilePathPasteSlideIndex].imagePath = pathString; 
    return(prevState); 
})
forceUpdate(); 
  
setShowFilemanagerModal(false); 


  }



  const {acceptedFiles, getRootProps, getInputProps, isDragActive,  isDragAccept, isDragReject} = useDropzone({onDrop, accept: 'image/*,video/*', maxSize: 15000000});
  
 

const baseStyle = {  
  };



  const activeStyle = {
    background: '#10d123', borderColor:'#fff'
  };
   

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {})
  }), [
    isDragActive
  ]);




useEffect(() => { 
    getSliderObject_ajax(); 
  }, []);






  const getFileListAjax = () =>{ 
    setLoadingFilesList(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash
    };
    axios.post(GLOBAL_apiPath+`user-files/list`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoadingFilesList(false); 
  
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! 
         setUserFilesArray(res.data.returnArray);
       }
    }).catch(err => {
      setLoadingFilesList(false); 
    })
  }


  const addNewFile = () =>{

    if(acceptedFiles.length>0){ 
      setLoadingUploadingFile(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash, 
        file: uploadBlobFile,
        fileName: acceptedFiles[0].path
    };
    axios.post(GLOBAL_apiPath+`user-files/create`, { obj })
    .then(res => {   
      setLoadingUploadingFile(false); 
      if(res.data.authError === true){ 
        contextObject._logout();
       }else{
         //sukces! - pobieramy więc aktualną listę
         getFileListAjax();
       }
    }).catch(err => {
      setLoadingUploadingFile(false); 
    })
 
    }else{
        contextObject._runAlert("Wymagane dodanie pliku");
    }

  }














  const getSliderObject_ajax = () =>{ 
    setLoading(true);  
    var obj = {
        email: contextObject.AUTH_userEmail,
        hash: contextObject.AUTH_userHash,
        rowId: sliderId
    };
    axios.post(GLOBAL_apiPath+`sliders/get-content`, { obj })
    .then(res => { 
       console.log(res.data);
       setLoading(false); 
  
      if(res.data.authError === true){ 
        console.log('log ałt');
        contextObject._logout();
       }else{
         //sukces!
         console.log('cukesik');



         
         if(res.data.returnObject.contentObject === null){
           //brak slideów - świeżo utworzony slider 
          setSliderObject((prevState)=>{ 
            prevState.contentObject[0] = sampleSlideObject;
            prevState.name = res.data.returnObject.name;
            return(prevState); 
        })
        forceUpdate(); 


         }else{
           //not null - czyli są slajdy!
          setSliderObject(res.data.returnObject);
         }


   

       }
    }).catch(err => {
      setLoading(false); 
    })
  }


 











  const handleItemEdit = () =>{
 

setLoading(true);  
var obj = {
    email: contextObject.AUTH_userEmail,
    hash: contextObject.AUTH_userHash,
    contentObject: sliderObject.contentObject,
    rowId: sliderId
};
axios.post(GLOBAL_apiPath+`sliders/edit`, { obj })
.then(res => {  
 
  setLoading(false);
  if(res.data.authError === true){ 
    contextObject._logout();
   }else{
     //sukces! - pobieramy więc aktualną listę
     getSliderObject_ajax();
   }
}).catch(err => {
  setLoading(false);
})
  }





 


    return(
<>



{showFilemanagerModal?(
<>

<div className="fixed_modal_bg">
</div>


<div className="main_modal_overall">
<div className="main_modal">
<div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
<p className="modal_title">Pliki</p>

<Link onClick={()=>{
  setShowFilemanagerModal(false);
}}>
<XSquare size={20} color="#5528e5" />
</Link>
</div>


<p style={{
  color: "#1f0573",
  fontSize: 15
}}>Twoje pliki:


{loadingFilesList? <Spinner animation="border" size="sm" variant="dark" className="main_spinner" /> : null} 


</p>

<div style={{
  overflow: 'auto', maxHeight: 600
}}>


{userFilesArray.map((fileItem,fileIndex) => (
  <Button className="main_input flex-center-center"
  onClick={()=>{
    pasteImagePath(fileItem.filePath);
  }}
  >

  {fileItem.isImage ?(
<img src={serwer_publicPath+fileItem.filePath} style={{height: 50, marginRight: 50}} />
  ):null}

  <p>{fileItem.name}</p>
  </Button> 
))}


</div>



<p style={{
  color: "#1f0573",
  fontSize: 15
}}>Dodaj nowy:</p>


<div style={{
  display: 'flex', justifyContent: 'center', alignItems: 'center'
}}>

<div {...getRootProps({className: 'dropzone dropzone-in-modal', style: style})}>
        <input {...getInputProps()} />
        <Upload />
      </div>


<Button className="main_btn" style={{paddingBottom: 20, paddingTop: 20, marginLeft: 5, width: '25%'}}
onClick={()=>addNewFile()}
>
{loadingUploadingFile? <Spinner animation="border" size="sm" variant="light" className="main_spinner" /> : "wgraj"} 
</Button>


      </div>
    

      {acceptedFiles.length>0? acceptedFiles[0].path:null}

      <br /><br /> 


</div>
</div>


</>
):null}










<h1 className="panel_page_title">
Edycja slide'ów
{loading? <Spinner animation="border" size="md" variant="dark" className="main_spinner" /> : null} 
</h1>

{sliderObject != undefined && sliderObject.name != undefined ?(
  <p>nazwa slidera w panelu: <b>{sliderObject.name}</b></p>
):null}


  
 

<div className="hr_separator"></div>

 


<p>Aktualizuj treść</p>

 
{sliderObject.contentObject.map((slideItem, slideItemIndex) => (
  <>
<p style={{fontWeight: 'bold'}}>Slajd: {slideItemIndex+1}</p>


<p className="p_over_input">Tytuł slajdu</p>
<input type="text" className="main_input"
value={slideItem.title}
onChange={(e)=>{
  const val = e.target.value;  
  setSliderObject((prevState)=>{ 
    prevState.contentObject[slideItemIndex].title = val; 
    return(prevState); 
})
forceUpdate(); 
}}
/>


<p className="p_over_input">Opis</p>
<textarea className="main_input"
value={slideItem.description}
onChange={(e)=>{
  const val = e.target.value;  
  setSliderObject((prevState)=>{ 
    prevState.contentObject[slideItemIndex].description = val; 
    return(prevState); 
})
forceUpdate(); 
}}
/>

 


<div className="hr_separator"></div>



<p className="p_over_input">Grafika</p>
<Button className="main_input flex-center-center" onClick={()=>{
  setTargetFilePathPasteSlideIndex(slideItemIndex);
  getFileListAjax();
  setShowFilemanagerModal(true);
}}>

{slideItem.imagePath != "" ?(
  <img src={serwer_publicPath+slideItem.imagePath} style={{height: 50, marginRight: 50}} />
  ):(
    <Folder size={40} color="#5b1de4" />
  )}


<p>{slideItem.imagePath}</p>
</Button>
<br />

<div className="flex-center-center" style={{width: '100%'}}>
<Link onClick={()=>{
  //bez locka dodawały się 2x itemy do galerii na raz...
 var lock = false; 
   setSliderObject((prevState)=>{ 
      
     if(lock === false){
       prevState.contentObject.splice(slideItemIndex, 1);
       lock = true;
     }
     
     return(prevState); 

 }) 



}}>
<XCircleFill color="#5b1de4" size={40} style={{marginTop:20}} />
</Link>
</div>

<div className="hr_separator"></div>



</>
))}
 

 

  <div className="flex-center-center" style={{width: '100%'}}>
  <Link onClick={()=>{
    //bez locka dodawały się 2x itemy do galerii na raz...
   var lock = false; 
     setSliderObject((prevState)=>{ 
        
       if(lock === false){
         prevState.contentObject.push(sampleSlideObject);
         lock = true;
       }
       
       return(prevState); 

   }) 
 
  

 }}>
 <PlusSquareFill color="#5b1de4" size={40} style={{marginTop:20}} />
 </Link>





  </div> 

 



<div className="hr_separator"></div>



<Button className="main_btn" style={{width: '100%', height: 60}}
onClick={()=>handleItemEdit()}
>
{loading? <Spinner animation="border" size="sm" variant="light" className="main_spinner" /> : "zapisz"} 
</Button>
 







</>
    );
}